@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
  letter-spacing: 0.2px;
}

body {
  margin: 0;
  --ref: 14;
  --c_ref: 16;
  --font_size: calc(var(--c_ref) * 1px);
  --max_width: 400px;
}

@font-face {
  font-family: "P22MackinacPro Regular";
  src: url("./font/P22_Mackinac/P22MackinacPro-Book_25.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CadizWeb Regular";
  src: url("./font/Cadiz_Web_Regular/CadizWeb-Regular.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Bold WEB";
  src: url("./font/Modern_Era_Bold_WEB/ModernEra-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Medium WEB";
  src: url("./font/Modern_Era_Medium_WEB/ModernEra-Medium.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono ExtraBold WEB";
  src: url("./font/Modern_Era_Mono_ExtraBold_WEB/ModernEraMono-ExtraBold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Bold WEB";
  src: url("./font/Modern_Era_Mono_Bold_WEB/ModernEraMono-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Regular WEB";
  src: url("./font/Modern_Era_Mono_Regular_WEB/ModernEraMono-Regular.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Medium WEB";
  src: url("./font/Modern_Era_Mono_Medium_WEB/ModernEraMono-Medium.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CadizWeb Bold";
  src: url("./font/Cadiz_Web_Bold/CadizWeb-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Permanent Marker";
}
@font-face {
  font-family: "P22MackinacPro Regular";
  src: url("./font/P22_Mackinac/P22MackinacPro-Book_25.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CadizWeb Regular";
  src: url("./font/Cadiz_Web_Regular/CadizWeb-Regular.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Bold WEB";
  src: url("./font/Modern_Era_Bold_WEB/ModernEra-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Medium WEB";
  src: url("./font/Modern_Era_Medium_WEB/ModernEra-Medium.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono ExtraBold WEB";
  src: url("./font/Modern_Era_Mono_ExtraBold_WEB/ModernEraMono-ExtraBold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Bold WEB";
  src: url("./font/Modern_Era_Mono_Bold_WEB/ModernEraMono-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Regular WEB";
  src: url("./font/Modern_Era_Mono_Regular_WEB/ModernEraMono-Regular.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Medium WEB";
  src: url("./font/Modern_Era_Mono_Medium_WEB/ModernEraMono-Medium.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "CadizWeb Bold";
  src: url("./font/Cadiz_Web_Bold/CadizWeb-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Permanent Marker";
}
:root {
  --black: #000;
  --white: #fff;
  --basewhite: #ffefef;
  --base: #5f1d1b;
  --base_light: #89302e;
  --brand-peach-soda: #ffa977;
  --accent-red-100: #ff5c5c;
  --brand-banana-ice-cream: #ffe7bf;
  --brand-banana-ice-cream2: #fffbf2;
  --baseOpas: #f5f5f5;
  --hague_blue: #333333;
  --nero: #1d1d1d;
  --grey99: #fafafa71;
  --grey98: #fafafa;
  --grey90: #e5e5e5;
  --grey33: #545454;
  --gray85: #d9d9d9;
  --gray500: #454545;
  --gray-g-200: #e5e5e5;
  --grey: #777777;
  --gray-g-500: #adadad;
  --gray-g-300: #c5c5c5;
  --gray-g-100: #f1f1f1;
  --red: #dd1d1d;
  --whitesmoke: #f5f5f5;
  --ligntwhite: #fafbfb;
  --lightgreen: #aee9d1;
  --lightblue: #a4e8f2;
  --gray111: #babfc3;
  --graim: #c9cccf;
  --nickel: #6d7175;
  --tropical_rain_forest: #008060;
  --accent-green-000: #3a7d23;
  --accent-green-001: #397d2377;
  --accent-green-100: #64be45;
  --dazzling_red: #d82c0d;
  --accent-green-200: #eff8ec;
  --milk-white: #fffbf2;
  --ocean: #2b3a33;
  --ocean_light: #d4dbcb;
  --ocean_light1: #f3f2e7;
  --Gray-G-500: #454545;
  --Gray-G-400: #777;
  --Gray-G-100: #f1f1f1;
  --Gray-B-100: #1d1d1d;
}

.cust_inp {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  line-height: 20px !important;
}
@media screen and (max-width: 1000px) {
  .cust_inp {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.cust_inp input,
.cust_inp textarea {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  line-height: 20px !important;
  font-family: "Modern Era Medium WEB";
  background-color: transparent;
}
@media screen and (max-width: 1000px) {
  .cust_inp input,
  .cust_inp textarea {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.cust_inp input::-moz-placeholder {
  font-weight: 400;
}
.cust_inp input::placeholder {
  font-weight: 400;
}
.cust_inp.text_group > label {
  position: relative;
}
.cust_inp.text_group > label .inp_title {
  position: absolute;
  left: 8px;
  padding: 0 8px;
  transform: translateY(-50%);
  margin: 0 0 9px 0;
}
@media screen and (max-width: 1000px) {
  .cust_inp.text_group > label .inp_title {
    margin: 0 0 2px 0;
    padding: 0 8px;
  }
}
.cust_inp.text_group > label input[type=text],
.cust_inp.text_group > label textarea,
.cust_inp.text_group > label input[type=number] {
  width: 100%;
  padding: 16px 14px;
  border: 1px solid var(--grey90);
  border-radius: 7px;
  outline: 0;
}
.cust_inp.text_group > label input[type=text][error=true],
.cust_inp.text_group > label textarea[error=true],
.cust_inp.text_group > label input[type=number][error=true] {
  border: 1px solid red;
}
@media screen and (max-width: 1000px) {
  .cust_inp.text_group > label input[type=text],
  .cust_inp.text_group > label textarea,
  .cust_inp.text_group > label input[type=number] {
    padding: 10px 12px 12px 10px;
  }
}
.cust_inp.email_group > label {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border: 1px solid #babfc3;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px 12px 8px 14px;
}
@media screen and (max-width: 1000px) {
  .cust_inp.email_group > label {
    width: 400px;
  }
}
.cust_inp.email_group > label .search_icons {
  display: flex;
  align-items: center;
}
.cust_inp.email_group > label input[type=text] {
  border: unset;
  padding: 0 20px 0 0;
  background-color: transparent;
  line-height: 20px !important;
}
.cust_inp.email_group > label input[type=text][error=true] {
  border: unset;
}
.cust_inp.cust_datepicker {
  width: 100%;
  position: relative;
  --calender_top: unset;
  --calender_bottom: 100%;
  --calender_height: 0;
}
.cust_inp.cust_datepicker .inp_title {
  margin: 0 0 9px 0;
  position: absolute;
  left: 8px;
  padding: 0 8px;
  transform: translateY(-50%);
  background-color: var(--white);
  color: rgba(197, 13, 109, 0.6);
  --fs: 20px;
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .cust_inp.cust_datepicker .inp_title {
    --fs: 20px;
    --lh: 150px;
    font-size: 20px;
    line-height: 150%;
  }
}
.cust_inp.cust_datepicker .datepicker_view {
  border: 1px solid var(--grey90);
  background-color: var(--white);
  border-radius: 7px;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 12px;
  justify-content: start;
  color: var(--base);
}
@media screen and (max-width: 1000px) {
  .cust_inp.cust_datepicker .datepicker_view {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_inp.cust_datepicker .datepicker_view .date_calender_icon {
  display: flex;
  margin: 0 10px 0 0;
  cursor: pointer;
}
.cust_inp.cust_datepicker .datepicker_view .calender_placeholder {
  color: var(--grey);
}
.cust_inp.cust_datepicker .cust_calender_main {
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  top: var(--calender_top);
  bottom: var(--calender_bottom);
  margin: 0 auto;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0px;
  overflow: hidden;
  border-radius: 4px;
  border: 0px solid var(--grey85);
  background: var(--white);
  z-index: 50;
  transition: height 0.3s ease-in-out, border 0s 0.3s linear;
}
.cust_inp.cust_datepicker .cust_calender_main.active {
  transition: height 0.3s ease-in-out, border 0s 0s linear;
  border: 1px solid var(--grey90);
  height: var(--calender_height);
}
.cust_inp.cust_datepicker .cust_calender_main .cust_calender {
  z-index: 4;
}
.cust_inp.cust_dropdown {
  font-family: "Modern Era Medium WEB";
  --dropdown_height: 52px;
  height: 52px;
  z-index: 10;
  position: relative;
}
.cust_inp.cust_dropdown.active_drop_down .dropdown_main .dropdown_submain .dropdown_items {
  color: var(--grey);
}
.cust_inp.cust_dropdown.active_drop_down .dropdown_main .dropdown_submain .dropdown_items.active {
  color: var(--grey);
}
.cust_inp.cust_dropdown .dropdown_main {
  border-radius: 6px;
  background: var(--white);
  position: absolute;
  width: 100%;
  border: 1px solid var(--grey90);
  overflow: hidden;
  height: 5px;
  transition: height 0.3s linear;
}
.cust_inp.cust_dropdown .dropdown_main .dropdown_submain {
  transform: translateY(0);
  transition: transform 0.3s linear;
}
.cust_inp.cust_dropdown .dropdown_main .dropdown_submain .dropdown_items {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  color: var(--black);
  cursor: pointer;
  height: 52px;
  display: flex;
  align-items: center;
  padding: 16px 0 16px 12px;
  transition: background 0.3s linear;
  border-block: 1px solid transparent;
  border-color: var(--grey90);
  background-color: var(--white);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
@media screen and (max-width: 1000px) {
  .cust_inp.cust_dropdown .dropdown_main .dropdown_submain .dropdown_items {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_inp.cust_dropdown .dropdown_main .dropdown_submain .dropdown_items.active {
  color: var(--black);
}
.cust_inp.cust_dropdown .dropdown_main .dropdown_arrow {
  position: absolute;
  top: 26px;
  right: 15px;
  height: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
}

.cust_input {
  --ref: 14;
  --c_ref: 16;
  --font_size: calc(var(--c_ref) * 1px);
  font-family: "Modern Era Medium WEB";
}
.cust_input > .input_group_main {
  outline: calc(1px * var(--c_ref) / var(--ref)) solid var(--gray-g-200);
  background-color: var(--white);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust_input > .input_group_main:focus-within {
  outline: calc(2px * var(--c_ref) / var(--ref)) solid var(--brand-peach-soda);
}
.cust_input > .input_group_main .input_prefix {
  display: flex;
  font-size: var(--font_size);
  background-color: transparent;
}
.cust_input > .input_group_main .input_prefix svg {
  height: calc(18px * var(--c_ref) / var(--ref));
  width: auto;
}
.cust_input > .input_group_main > .input_group2 {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.cust_input > .input_group_main > .input_group2 .cust_input_title {
  position: absolute;
  height: -moz-fit-content;
  height: fit-content;
  top: calc((48px * var(--c_ref) / var(--ref) - var(--font_size)) / 2);
  left: calc(11px * var(--c_ref) / var(--ref));
  margin: auto 0;
  font-size: var(--font_size);
  line-height: 1;
  transform-origin: top left;
  transition: transform 0.2s linear, bottom 0.2s linear, top 0.2s linear, font-size 0.2s linear, top 0.2s linear;
  color: var(--grey);
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cust_input > .input_group_main > .input_group2 .cust_input_title > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 0;
}
.cust_input > .input_group_main > .input_group2 .cust_input_title[active=true] {
  top: calc(9px * var(--c_ref) / var(--ref));
  font-size: calc(12px * var(--c_ref) / var(--ref));
}
.cust_input > .input_group_main > .input_group2 input::-moz-placeholder {
  color: var(--gray-g-500);
}
.cust_input > .input_group_main > .input_group2 input::placeholder {
  color: var(--gray-g-500);
}
.cust_input > .input_group_main > .input_group2 > input {
  background-color: transparent;
  border: 0;
  width: 100%;
  font-family: "Modern Era Medium WEB";
  color: var(--hague_blue);
  font-size: var(--font_size);
  height: calc(48px * var(--c_ref) / var(--ref));
  outline: none;
}
.cust_input > .input_group_main > .input_group2 > input:-moz-read-only {
  pointer-events: none !important;
}
.cust_input > .input_group_main > .input_group2 > input:read-only {
  pointer-events: none !important;
}
.cust_input > .input_group_main > .input_group2 > input.active_pad {
  padding: calc(21px * var(--c_ref) / var(--ref)) calc(9px * var(--c_ref) / var(--ref)) calc(4px * var(--c_ref) / var(--ref)) calc(11px * var(--c_ref) / var(--ref));
}
.cust_input > .input_group_main > .input_group2 > input.unactive_pad {
  padding: 16px 10px 16px 12px;
}
.cust_input > .input_group_main > .input_group2 > input::-moz-placeholder {
  font-size: var(--font_size);
}
.cust_input > .input_group_main > .input_group2 > input::placeholder {
  font-size: var(--font_size);
}
.cust_input > .input_group_main > .input_group2 > input:focus-visible ~ .cust_input_title {
  top: calc(9px * var(--c_ref) / var(--ref));
  font-size: calc(12px * var(--c_ref) / var(--ref));
}
.cust_input .input_error {
  overflow: hidden;
  color: red;
  font-size: var(--font_size);
  line-height: 1.3;
  padding: 0 0 0 0;
  height: 0;
  transition: height 0.3s linear;
}
.cust_input .input_error > div {
  padding: calc(5px * var(--c_ref) / var(--ref)) 0 0 0;
}
.cust_input.err_active .input_group_main {
  outline: calc(1px * var(--c_ref) / var(--ref)) solid var(--red);
}
.cust_input.err_active .input_error {
  height: calc(5px * var(--c_ref) / var(--ref) + var(--font_size) * 1.3);
}
.cust_input.with_prefix .input_prefix,
.cust_input.with_prefix .input_suffix {
  color: var(--grey);
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  font-weight: 400;
  line-height: 20px; /* 125% */
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .cust_input.with_prefix .input_prefix,
  .cust_input.with_prefix .input_suffix {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_input.with_prefix .input_prefix {
  margin: 0 calc(3.75px * var(--c_ref) / var(--ref)) 0 calc(11px * var(--c_ref) / var(--ref));
  align-self: stretch;
}
.cust_input.with_prefix .input_suffix {
  margin: 0 calc(11px * var(--c_ref) / var(--ref)) 0 0;
}

.cust_search {
  border: 1px solid var(--gray-g-200);
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cust_search .svg_group {
  margin: 6px 0 6px 12px;
  height: 20px;
  width: 20px;
}
.cust_search input {
  outline: 0;
  border: 0;
  width: 100%;
  align-self: stretch;
}
.cust_search input:focus-visible {
  outline: 0;
  border: 0;
}

.cust_dropdown {
  border: 1px solid var(--gray-g-200);
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  font-family: "Modern Era Medium WEB";
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .cust_dropdown {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.cust_dropdown .to_show {
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cust_dropdown .to_show .svg_group {
  height: 10px;
  width: 10px;
}
.cust_dropdown .options_main {
  z-index: 100;
  position: absolute;
  max-height: 0;
  top: calc(100% + 10px);
  right: 0;
  width: 350px;
  transition: max-height 1s ease-in-out;
  overflow: hidden;
  background: var(--white);
  max-height: 500px;
}
.cust_dropdown .options_main[align=left_align] {
  right: unset;
  left: 0;
}
.cust_dropdown .options_main .options {
  max-height: 500px;
  overflow: auto;
  border: 1px solid var(--gray-g-200);
  padding: 5px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .cust_dropdown .options_main .options {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_dropdown .options_main .options .items {
  border-radius: 4px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cust_dropdown .options_main .options .items[active=true] {
  background: var(--gray-g-100);
}
.cust_dropdown .options_main .options .items .cust_checkbox {
  border: 1px solid var(--gray-g-200);
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--white);
  transition: background 0.3s linear;
}
.cust_dropdown .options_main .options .items .cust_checkbox[active=true] {
  background: var(--black);
}
.cust_dropdown .options_main .options .items .cust_checkbox svg {
  height: 10px;
  width: 10px;
  --svg_color: var(--white);
}

.dropdown_multi_select {
  position: relative;
}
.dropdown_multi_select .dropdown_multi_counts {
  position: absolute;
  top: -6px;
  right: -5px;
  background-color: var(--accent-green-100);
  color: var(--white);
  height: 17px;
  width: 17px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .dropdown_multi_select .dropdown_multi_counts {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
.dropdown_multi_select .dropdown_closer {
  position: fixed;
  pointer-events: auto;
  inset: 0;
  z-index: 100;
}
.dropdown_multi_select .dropdown_body {
  position: absolute;
  background: var(--white);
  top: calc(100% + 6px);
  left: 0;
  z-index: 100;
  box-shadow: 0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08);
  padding: 16px;
  width: -moz-fit-content;
  width: fit-content;
}
.dropdown_multi_select .dropdown_body .dropdown_item {
  display: flex;
  gap: 8px;
}
.dropdown_multi_select .dropdown_body .dropdown_item > .txt {
  white-space: nowrap;
}
.dropdown_multi_select .dropdown_body .dropdown_item:not(:last-child) {
  margin: 0 0 14px 0;
}

.customdrop_down_all .customdrop_down_all_title {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 6px;
  padding: 6px 12px;
  border-radius: 100px;
}
.customdrop_down_all .customdrop_down_all_list {
  height: 0;
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 6px 0;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 3px 6px -3px rgba(23, 24, 24, 0.08);
}
.customdrop_down_all .customdrop_down_all_list > div {
  padding: 10px;
}

.cust_simple_drop_down {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  position: relative;
  background-color: var(--white);
}
@media screen and (max-width: 1000px) {
  .cust_simple_drop_down {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_simple_drop_down .inner_cust_simple_drop_down {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid var(--grey90);
  text-transform: capitalize;
}
.cust_simple_drop_down .inner_cust_simple_drop_down .inner_cust_simple_drop_down_icons {
  display: flex;
  align-items: center;
}
.cust_simple_drop_down .inner_cust_simple_drop_down_list {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 6px 0;
  padding: 16px 12px;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid var(--grey90);
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: var(--white);
  text-transform: capitalize;
}

.different_selector_main {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 12px;
  font-family: "Modern Era Medium WEB";
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .different_selector_main {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.different_selector_main .different_selector_inner_active {
  display: flex;
  align-items: center;
  gap: 0 6px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 2px solid var(--gray-g-100);
  background: var(--white);
}
.different_selector_main .different_selector_inner {
  display: flex;
  align-items: center;
  gap: 0 6px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 2px dashed var(--gray-g-100);
  background: var(--white);
  cursor: pointer;
}
.different_selector_main .different_selector_inner .different_selector_inner_title {
  font-family: "Modern Era Bold WEB";
  color: var(--nero);
}

.different_selector_main_active {
  font-family: "Modern Era Medium WEB";
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  gap: 6px 12px;
  padding: 0 0 18px 0;
}
@media screen and (max-width: 1000px) {
  .different_selector_main_active {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.different_selector_main_active .different_selector_inner_active {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0 6px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 2px solid var(--gray-g-100);
  background: var(--white);
}
.different_selector_main_active .different_selector_inner_active .different_selector_inner_active_title {
  font-family: "Modern Era Bold WEB";
}

.order_status {
  padding: 6px 12px;
  background-color: var(--whitesmoke);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 100px;
}

.order_priority {
  padding: 6px 12px;
  background-color: var(--whitesmoke);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 100px;
}

.cust_multi_select_drop_down {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  position: relative;
  background-color: var(--white);
}
@media screen and (max-width: 1000px) {
  .cust_multi_select_drop_down {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
.cust_multi_select_drop_down .inner_cust_multi_select_drop_down {
  padding: 16px 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid var(--grey90);
}
.cust_multi_select_drop_down .inner_cust_multi_select_drop_down .inner_cust_simple_drop_down_title {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.cust_multi_select_drop_down .inner_cust_multi_select_drop_down .inner_cust_simple_drop_down_title .inner_cust_simple_drop_down_title_inner {
  width: -moz-fit-content;
  width: fit-content;
  border: solid 1px var(--gray111);
  border-radius: 10px;
  padding: 4px 10px;
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .cust_multi_select_drop_down .inner_cust_multi_select_drop_down .inner_cust_simple_drop_down_title .inner_cust_simple_drop_down_title_inner {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.cust_multi_select_drop_down .inner_cust_multi_select_drop_down .inner_cust_simple_drop_down_icons {
  display: flex;
  align-items: center;
}
.cust_multi_select_drop_down .inner_cust_multi_select_drop_down .inner_cust_simple_drop_down_icons.inner_cust_simple_drop_down_icons_active {
  transform: rotate(180deg);
}
.cust_multi_select_drop_down .inner_cust_simple_drop_down_list {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: 8px 0;
  justify-content: space-between;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  background-color: var(--white);
  text-transform: capitalize;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cust_multi_select_drop_down .inner_cust_simple_drop_down_list .inner_cust_simple_drop_down_list_inner {
  padding: 6px 16px;
}
.cust_multi_select_drop_down .inner_cust_simple_drop_down_list .inner_cust_simple_drop_down_list_inner:hover {
  background-color: rgba(109, 179, 250, 0.12);
}
.cust_multi_select_drop_down .inner_cust_simple_drop_down_list .inner_cust_simple_drop_down_list_inner.active_drop_down {
  background-color: rgba(25, 118, 210, 0.12);
}

.custtable {
  padding: 0;
  border-radius: 0.5rem;
  overflow: auto;
  width: 100%;
  border: 1px solid var(--gray-g-200);
  height: 100%;
  --srno: 40px;
}
.custtable .header {
  padding: 0 !important;
  display: flex;
  position: sticky;
  top: 0;
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: var(--grey);
  border-bottom: 1px solid var(--gray-g-200);
  text-transform: uppercase;
  background: var(--white);
}
@media screen and (max-width: 1000px) {
  .custtable .header {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
.custtable .header > div {
  --width: 100;
  width: calc((100% - var(--srno)) * (var(--width)) / 100);
  font-size: 10px;
  font-family: "Modern Era Bold WEB";
  padding: 18px 16px;
}
.custtable .body {
  padding: 0 !important;
  font-family: "Modern Era Medium WEB";
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .custtable .body {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
.custtable .body .row {
  line-height: 1;
  padding: 0 !important;
  display: flex;
}
.custtable .body .row > div {
  --width: 100;
  width: calc((100% - var(--srno)) * (var(--width)) / 100);
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  padding: 18px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-g-200);
  flex-grow: 0;
}
@media screen and (max-width: 1000px) {
  .custtable .body .row > div {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
.custtable .body .row .order_status {
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: var(--gray-g-100);
}
.custtable .srno {
  width: var(--srno) !important;
  justify-content: center;
}

.intake_view {
  width: 100%;
}
.intake_view .intake_card {
  page-break-inside: avoid;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
  font-family: "Modern Era Medium WEB";
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  margin: 0 0 16px 0;
}
.intake_view .intake_card > div {
  margin: 0 0 20px 0;
}
.intake_view .intake_card > div.intake_title {
  color: var(--Gray-B-100, #1d1d1d);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
.intake_view .intake_card > div.intake_flex {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
.intake_view .intake_card > div.intake_flex .input_flex {
  display: flex;
  gap: 20px;
}
.intake_view .intake_card > div.intake_flex .input_flex .cust_input {
  width: 100%;
}
.intake_view .intake_card > div.intake_flex .input_group {
  width: 100%;
}
.intake_view .intake_card > div.intake_flex .input_group .input_group_header {
  color: var(--Gray-G-400, #777);
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.2px;
  margin: 0 0 6px 0;
  text-transform: uppercase;
}
.intake_view .intake_card > div.intake_flex .input_group .input_group_value .intake_chip_flex {
  display: flex;
  align-items: center;
  gap: 0 8px;
  flex-wrap: wrap;
}
.intake_view .intake_card > div.intake_flex .input_group .input_group_value .intake_chip_flex > div {
  white-space: nowrap;
}
.intake_view .intake_card > div.intake_flex .input_group .input_group_value .intake_chip {
  align-items: center;
  background: #fff;
  border: 2px solid #f1f1f1;
  border-radius: 100px;
  cursor: pointer;
  padding: 6px 12px;
  margin: 0 0 10px 0;
  width: -moz-fit-content;
  width: fit-content;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  --svg_color: var(--gray500);
  color: var(--nero);
  font-family: "Modern Era Medium WEB";
}
body .layout {
  height: 100vh;
}
body .layout .svg_group {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .layout .svg_group svg {
  height: 100%;
  width: 100%;
}
body .layout .dashboard_main {
  display: flex;
  height: 100vh;
}
body .layout .dashboard_main .dashboard_nav {
  box-shadow: -1px 0px 0px 0px #e4e5e7 inset;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.171);
}
body .layout .dashboard_main .dashboard_nav .logo {
  height: 20px;
  width: auto;
  margin: 0 auto 34px 8px;
  --svg_color: var(--black);
}
body .layout .dashboard_main .dashboard_nav .nav_items {
  cursor: pointer;
  width: 100%;
  padding: 10px 12px;
  font-family: "Modern Era Medium WEB";
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  color: var(--nero);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--grey);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_nav .nav_items {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_nav .nav_items svg {
  --svg_color: var(--grey);
  width: 16px;
  height: 16px;
}
body .layout .dashboard_main .dashboard_nav .nav_items[is_active=true] {
  font-family: "Modern Era Bold WEB";
  color: var(--nero);
  color: var(--white);
  background: var(--base);
}
body .layout .dashboard_main .dashboard_nav .nav_items[is_active=true] svg {
  --svg_color: var(--nero);
  --svg_color: var(--white);
}
body .layout .dashboard_main .dashboard_nav .profile {
  min-width: 150px;
  max-width: 150px;
  margin: auto 0 0 0;
  border: 50%;
  color: var(--white);
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  gap: 10px;
  background: var(--grey98);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_nav .profile {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_nav .profile .avatar {
  background: #f2c0b2;
  height: 16px;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
}
body .layout .dashboard_main .dashboard_nav .profile .name {
  font-family: "Modern Era Bold WEB";
  color: var(--nero);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
body .layout .dashboard_main .dashboard_nav .profile .logout {
  display: flex;
  margin: 0 0 0 auto;
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  color: var(--grey);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_nav .profile .logout {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_nav .profile .logout svg {
  height: 20px;
  width: 20px;
}
body .layout .dashboard_main .dashboard_nav .profile .logout svg path {
  fill: var(--grey);
}
body .layout .dashboard_main .dashboard_subheader {
  display: flex;
  align-items: center;
  gap: 8px;
}
body .layout .dashboard_main .dashboard_subheader .search_and_filter {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 0 0 auto;
}
body .layout .dashboard_main .dashboard_subheader .search_and_filter .cust_search {
  max-width: 320px;
  flex-grow: 1;
}
body .layout .dashboard_main .dashboard_subheader .search_and_filter .dropdown_group {
  display: flex;
  gap: 8px;
  margin: 0 0 0 auto;
}
body .layout .dashboard_main .dashboard_body {
  flex-grow: 1;
  overflow: scroll;
  padding: 25px 25px 15px 25px;
}
body .layout .dashboard_main .dashboard_page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .dashboard_page .order_type_header {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-bottom: 1px solid var(--gray-g-200);
  padding: 20px 25px 0px 25px;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header {
  display: flex;
  align-items: center;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header .txt1 {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  color: #454545;
  margin: 0 0 0 auto;
  font-family: "Modern Era Medium WEB";
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .order_type_header .header .txt1 {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .order_type_header .header .txt1 div {
  display: flex;
  --svg_color: #454545;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb {
  display: flex;
  align-items: center;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .back_btn {
  border: 1px solid var(--gray-g-200);
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items {
  display: flex;
  align-items: center;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(2) {
  --fs: 20px;
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(2) {
    --fs: 20px;
    --lh: 150px;
    font-size: 20px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(3), body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(1) {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid var(--gray-g-200);
  background: var(--grey98);
  padding: 2px 8px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(3), body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(1) {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(1) {
  padding: 5px;
  margin: 0 10px 0 0;
  cursor: pointer;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header1 .breadcrumb .breadcrumb_items:nth-child(1) svg {
  transform: rotate(180deg);
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header2 {
  color: var(--grey);
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  gap: 6px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .order_type_header .header.header2 {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header2 .username {
  text-decoration: underline;
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header3 {
  margin: 16px 0 0 0;
  padding: 10px 0;
  gap: 24px;
  font-family: "Modern Era Bold WEB";
  color: var(--grey);
}
body .layout .dashboard_main .dashboard_page .order_type_header .header.header3 [active=true] {
  color: var(--nero);
}
body .layout .dashboard_main .dashboard_page .preview_images {
  z-index: 100;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.301);
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .preview_images .order_cancel_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white);
  padding: 32px;
  max-width: 640px;
  border-radius: 12px;
  gap: 20px 0;
}
body .layout .dashboard_main .dashboard_page .preview_images .order_cancel_main .btns {
  display: flex;
  justify-content: start;
  gap: 0 10px;
  width: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body {
  display: flex;
  gap: 50px;
  overflow-x: hidden;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--gray-g-200);
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 22px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group {
  display: flex;
  gap: 50px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .title {
  font-family: "Modern Era Bold WEB";
  --fs: 10px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 800;
  margin: 0 0 8px 0;
  color: var(--Gray-G-400, #777);
  text-transform: uppercase;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .title {
    --fs: 10px;
    --lh: 150px;
    font-size: 10px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .delete_order {
  margin: 0 0 0 auto;
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .body {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  color: var(--Gray-G-500, #454545);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .body {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .invoice_group .body,
body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .status_group .body {
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 100px;
  background: var(--Gray-G-100, #f1f1f1);
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 2px 8px;
  color: var(--Gray-B-100, #1d1d1d);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .invoice_group .body,
  body .layout .dashboard_main .dashboard_page .dashboard_body .summary .amount_main_group .status_group .body {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left {
  width: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--gray-g-200);
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary svg {
  --svg_color: var(--gray-g-300);
  height: 16px;
  width: auto;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .address_group {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .address,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .report_date {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .address,
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .report_date {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .address span,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .report_date span {
  color: var(--Gray-G-400);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .address .edit_btn,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .report_date .edit_btn {
  padding: 6px 12px;
  border-radius: 100px;
  background: var(--Gray-G-100, #f1f1f1);
  cursor: pointer;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .copy {
  cursor: pointer;
  display: flex;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group {
  display: flex;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group > div {
  width: 50%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .phone {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .lab_details,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .technician_details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  color: var(--Gray-G-500, #454545);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .lab_details,
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .technician_details {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .lab_details .title,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .technician_details .title {
  color: var(--Gray-G-400);
  text-transform: uppercase;
  --fs: 10px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .lab_details .title,
  body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .technician_details .title {
    --fs: 10px;
    --lh: 150px;
    font-size: 10px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .lab_details .tbd,
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .lab_summary .lab_data_group .technician_details .tbd {
  color: var(--Gray-G-300, #c5c5c5);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_left .bundle_items {
  max-height: 100%;
  align-self: stretch;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .body_right {
  width: 50%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .lab_action_group > div {
  display: flex;
  align-items: center;
  gap: 0 10px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .lab_action_group > div:first-child {
  margin: 0 0 10px 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner {
  width: 640px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
  padding: 32px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_title {
  --fs: 18px;
  font-size: 18px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Modern Era Bold WEB";
  line-height: 24px;
  margin: 0 0 24px 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_title {
    --fs: 18px;
    --lh: 150px;
    font-size: 18px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin {
  margin: 24px 0 0 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_title {
  --fs: 20px;
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_title {
    --fs: 20px;
    --lh: 150px;
    font-size: 20px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_date_slot {
  display: flex;
  margin: 0 0 16px 0;
  gap: 0 6px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_date_slot .your_date_slot_box {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  line-height: 20px !important;
  padding: 15px 11px;
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid var(--grey90);
  border-radius: 6px;
  color: var(--grey);
  width: 33.3333333333%;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_date_slot .your_date_slot_box {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_date_slot .your_date_slot_box:hover {
  background-color: var(--grey98);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_date_slot .your_date_slot_box.your_date_slot_box_active {
  font-family: "Modern Era Bold WEB";
  color: var(--white);
  background-color: var(--base);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_desc {
  margin: 0 0 16px 0;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  color: var(--grey);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_desc {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set {
  max-height: 257px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  line-height: 20px; /* 125% */
  color: var(--grey);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .no_time_slot {
  width: 100%;
  text-transform: uppercase;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .time_box {
  width: calc(50% - 8px);
  padding: 15px 11px;
  background-color: var(--white);
  border: 1px solid var(--grey90);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  gap: 0 3.5px;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .time_box:hover {
  background-color: var(--grey98);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .time_box .time_box_tick {
  display: flex;
  margin: 0 0 0 auto;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .time_box .time_box_tick {
    display: none;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .your_time_slot_admin .your_time_slot_data_set .time_box.active_box {
  font-family: "Modern Era Bold WEB";
  color: var(--base);
  background-color: var(--brand-banana-ice-cream);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .no_time_slot_admin {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  font-family: "Modern Era Medium WEB";
  margin: 8px 0 0 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .no_time_slot_admin {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .fetching_slots_title {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  font-family: "Modern Era Medium WEB";
  margin: 8px 0 0 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .fetching_slots_title {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .between_line {
  border-top: 1px solid var(--grey90);
  margin: 24px 0 24px 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_1 {
  display: flex;
  align-items: center;
  gap: 0 6px;
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Modern Era Bold WEB";
  line-height: 20px; /* 166.667% */
  color: var(--grey);
  margin: 0 0 12px 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_1 {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_2 {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
  line-height: 20px; /* 142.857% */
  color: var(--grey);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_2 {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_3 {
  --fs: 20px;
  font-size: 20px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Modern Era Bold WEB";
  line-height: 26px; /* 130% */
  margin: 12px 0 0 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_detail .booking_controler_detail_3 {
    --fs: 20px;
    --lh: 150px;
    font-size: 20px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn {
  display: flex;
  align-items: end;
  gap: 0 10px;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 700;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn > div {
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn .booking_btn_canc {
  border: 1px solid var(--gray-g-200);
  background: var(--grey98);
  color: var(--gray500);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn .booking_btn_save {
  color: var(--gray-g-300, #c5c5c5);
  border: 1px solid var(--gray-g-200, #e5e5e5);
  background: var(--gray-g-000, #fafafa);
  cursor: no-drop;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn .booking_btn_save.book_active {
  background-color: var(--black);
  color: var(--white);
  cursor: pointer;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .booking_modal_main .booking_modal_inner .booking_controler_section .booking_controler_btns_stn .booking_btn_save2 {
  background-color: var(--black);
  color: var(--white);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
  background: var(--white);
  padding: 20px 0 0 0;
  z-index: 1;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_header .options_main {
  width: -moz-fit-content;
  width: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body {
  display: flex;
  gap: 24px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body > div {
  width: calc(33.3333333333% - 16px);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main {
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex {
  display: flex;
  justify-content: space-between;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex .cust_input {
  width: 100%;
  margin: 12px 0 0 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex .value_txt {
  font-family: "Modern Era Medium WEB";
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  text-transform: capitalize;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex .value {
  font-family: "Modern Era Medium WEB";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex .name {
  color: var(--Gray-G-400, #777);
  font-family: "Modern Era Medium WEB";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex.main {
  --fs: 18px;
  font-family: Modern Era Bold WEB;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 150%;
  line-height: 20px !important;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .flex.main .short_name {
  color: #5f1d1b;
  color: var(--base);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .range_header {
  display: flex;
  align-items: center;
  justify-content: start;
  border-top: 1px solid var(--grey90);
  color: var(--grey);
  font-family: Modern Era Bold WEB;
  font-size: 12px;
  font-weight: 400;
  gap: 0 6px;
  line-height: 1 !important;
  padding: 16px 0 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .range_header svg path {
  stroke: rgb(119, 119, 119);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .description {
  margin: 16px 0 16px 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .ranges_group {
  margin: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .ranges_group > div {
  width: 100%;
  --fs: 12px;
  background: #fafafa;
  background: var(--grey98);
  font-family: Modern Era Medium WEB;
  font-size: 12px;
  font-weight: 400;
  font-weight: 400 !important;
  line-height: 150%;
  padding: 8px 0;
  text-align: center;
  width: 33%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .ranges_group > div:not(.active) {
  color: #777;
  color: var(--grey);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_left .biomarker_main .ranges_group > div > div {
  white-space: nowrap;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  gap: 16px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right.cards_group .result_pdf {
  border: 1px solid #e5e5e5;
  padding: 20px;
  border-radius: 16px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right.cards_group .result_pdf input {
  display: none;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right.cards_group .result_pdf .pdf_show {
  display: flex;
  gap: 10px;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right.cards_group .result_pdf .pdf_show .pdf_img {
  height: 80px;
  width: 80px;
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  border: 2px solid #e5e5e5;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right.cards_group .result_pdf .pdf_show .chips {
  margin: 8px 0 0 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right .description {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Base-W-100, #fff);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right .description .desc_title {
  font-family: "Modern Era Medium WEB";
  color: var(--Gray-B-100, #1d1d1d);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 142.857% */
}
body .layout .dashboard_main .dashboard_page .dashboard_body .result_main .result_body .result_right .description .desc_body {
  font-family: "Modern Era Medium WEB";
  color: var(--Gray-G-400, #777);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main {
  width: 100%;
  margin: 0 auto;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .intake_view {
  margin: 0 auto;
  -moz-column-count: 2;
       column-count: 2;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .actions {
  margin: 0 0 12px 0;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .actions .btn {
  width: -moz-fit-content;
  width: fit-content;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main s .invoice_flex {
  display: flex;
  gap: 50px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main s .invoice_flex > div {
  width: 50%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  font-family: "Modern Era Medium WEB";
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box:not(:last-child) {
  margin: 0 0 16px 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .title {
  color: var(--Gray-B-100, #1d1d1d);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 166.667% */
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .flex {
  display: flex;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .flex:not(:last-child) {
  margin: 0 0 20px 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .group {
  width: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .group .group_header {
  color: var(--Gray-G-400, #777);
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px; /* 160% */
  letter-spacing: 0.2px;
  margin: 0 0 6px 0;
  text-transform: uppercase;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .group .group_body {
  color: var(--Base-B-100, #1d1d1d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
body .layout .dashboard_main .dashboard_page .dashboard_body .invoice_main .box .group .group_value {
  display: flex;
  gap: 6px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header {
  display: flex;
  margin: 0 0 20px 0;
  gap: 16px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header > .header_items {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 0;
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 700;
  color: var(--Gray-G-400, #777);
  --svg_color: #c5c5c5;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header > .header_items {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header > .header_items .count {
  --fs: 8px;
  font-size: 8px;
  line-height: 100%;
  font-weight: 400;
  padding: 2px 3px;
  border-radius: 3px;
  background: var(--Gray-G-100, #f1f1f1);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header > .header_items .count {
    --fs: 8px;
    --lh: 100px;
    font-size: 8px;
    line-height: 100%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .header > .header_items[active=true] {
  color: var(--Base-B-100, #1d1d1d);
  --svg_color: #454545;
  border-bottom: 1px solid #454545;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body {
  position: relative;
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 4px;
  left: 9.5px;
  background: var(--Gray-G-200, #e5e5e5);
  width: 2px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item > div {
  margin: 0 0 10px 0;
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  padding: 4px 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item > div {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item:last-child > div {
  margin: 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .date_change {
  margin: 0;
  background: var(--white);
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .date_change {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .date_change > div {
  padding: 2px 6px;
  border-radius: 5px;
  background: var(--Gray-G-100);
  width: -moz-fit-content;
  width: fit-content;
  color: var(--Gray-G-400);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-shrink: 1;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .icon {
  border-radius: 5px;
  border: 1px solid rgba(255, 169, 119, 0.5019607843);
  outline: 4px solid #fff;
  background: #ffe3d2;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .icon svg {
  width: 12px;
  height: 12px;
  --svg_color: var(--base);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-shrink: 1;
  flex-grow: 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .bold {
  font-family: "Modern Era Bold WEB";
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .highlighted {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-G-000, #fafafa);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .status {
  padding: 2px 8px;
  white-space: nowrap;
  border-radius: 100px;
  background: var(--Gray-G-100, #f1f1f1);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .status[status=low] {
  color: #f59e0b;
  background: #fef3c7;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .status[status=medium] {
  color: #f97316;
  background: #ffedd5;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .txt .status[status=high] {
  color: #ef4444;
  background: #fee2e2;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .time {
  margin: 0 0 0 auto;
  flex-shrink: 0;
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  color: var(--Gray-G-400, #777);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex .time {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=milestone] {
  background: var(--white);
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  width: -moz-fit-content;
  width: fit-content;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=milestone] {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=milestone] svg {
  --svg_color: #276cf0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=milestone] .txt {
  padding: 4px;
  border-radius: 5px;
  border: 1px solid #d7e5ff;
  background: #e5eeff;
  color: #276cf0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] {
  align-items: start;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group {
  flex-grow: 1;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .createdby {
  display: flex;
  align-items: center;
  padding: 0 0 6px 0;
  gap: 10px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .createdby .time {
  line-height: 1;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .createdby .edit {
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .createdby .edit {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .createdby .bold {
  font-family: "Modern Era Bold WEB";
  text-transform: capitalize;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt {
  border-radius: 5px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  padding: 12px 10px;
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  color: var(--Gray-G-400, #777);
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt:not(:first-child) {
  margin: 10px 0 0 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt .image {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt .prescription {
  border: 1px solid #e5e5e5;
  margin: 3px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .activity_item .item_flex[type=note] .note_group .txt.image_group {
  padding: 0;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add {
  padding: 0 0 20px 0;
  background: var(--white);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add textarea {
  width: 100%;
  resize: none;
  padding: 16px 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  font-family: "Modern Era Medium WEB";
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add textarea:focus-visible {
  outline: 1px solid var(--base);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add .action_group {
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add .action_group .attach {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  gap: 6px;
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add .action_group .attach {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add .action_group .add_btn {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  line-height: 1;
  border-radius: 6px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-G-000, #fafafa);
  padding: 8px 10px;
  color: var(--Gray-G-300, #c5c5c5);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_add .action_group .add_btn {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group {
  background-color: #fff;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .order_input {
  width: 100%;
  resize: none;
  padding: 16px 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  font-family: "Modern Era Medium WEB";
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .order_input:focus-visible {
  outline: 2px solid var(--brand-peach-soda);
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .order_image {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Modern Era Bold WEB";
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  gap: 0 8px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .order_image {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .order_image input {
  display: none;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .notes_flex {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .notes_flex .cancel_btn {
  margin: 0 0 0 auto;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group.b_top {
  padding: 8px 0 0 0;
  border-top: 1px solid #e5e5e5;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div {
  display: flex;
  gap: 10px;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div * {
  white-space: nowrap;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div svg {
  flex-shrink: 0;
  height: 20px;
  width: 20px;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div .preview {
  cursor: pointer;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  text-decoration: underline;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div .preview {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div .name {
  flex-grow: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .notes_group .image_group > div .delete {
  flex-grow: 1;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images {
  z-index: 100;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.856);
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .close {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: -moz-fit-content;
  width: fit-content;
  aspect-ratio: 1;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slider_main {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider {
  width: calc(100% - 60px);
  height: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-list,
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track {
  height: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track {
  display: flex;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track .slick-slide {
  height: auto;
  align-self: stretch;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track .slick-slide > div {
  height: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track .slick-slide > div > div {
  height: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slick-track .slick-slide > div > div > div {
  height: 100%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slider_main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images .slick-slider .slider_main img {
  background: #fff;
  max-width: 80%;
  max-height: 80%;
}
body .layout .dashboard_main .dashboard_page .dashboard_body .activity_tracker_group .body .preview_images img {
  max-width: 80%;
  max-height: 80%;
}
body .layout .dashboard_main .dashboard_page .bundle_body {
  flex-direction: column;
  padding: 20px 22px;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .bundle_body .summary {
  margin: 0;
}
body .layout .dashboard_main .dashboard_page .bundle_body .custtable {
  overflow: unset;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .divider {
  background: var(--Gray-G-200, #e5e5e5);
  height: 1px;
  width: 100%;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .divider:last-child {
  display: none;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .appointmentdropdown .items {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .session_count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .session_count .counter {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-G-000, #fafafa);
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .session_count .switch {
  width: 40px;
  height: 20px;
  padding: 2px;
  position: relative;
  border-radius: 12px;
  background: var(--Gray-G-200, #e5e5e5);
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .session_count .switch .switcher {
  margin: 2px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 16px;
  aspect-ratio: 1;
  background: var(--white);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body .item_data > div {
  display: flex;
  gap: 6px;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body .item_data > div:not(:last-child) {
  margin: 0 0 8px 0;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body .item_data .link {
  text-decoration: underline;
  cursor: pointer;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body .complet_btn {
  margin: 0 0 0 auto;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items .accordian_body {
  height: 0;
  overflow: hidden;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items[active=true] .accordian_body {
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count {
  gap: 20px;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count .switch {
  margin: 0 0 0 auto;
  flex-shrink: 0;
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count .follow_up {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-G-000, #fafafa);
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count .follow_up {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count .optional {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  color: var(--Gray-G-300, #c5c5c5);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .appointmentdropdown .items.follow_up_main .session_count .optional {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .dashboard_page .appointment_body .lab_summary > .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .layout .dashboard_main .dashboard_page .appointment_body .lab_summary > .title .goto {
  display: flex;
  align-items: center;
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 100px;
  background: var(--Gray-G-100, #f1f1f1);
  padding: 2px 8px;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .dashboard_page .appointment_body .lab_summary > .title .goto {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .orders_page .dashboard_subbody {
  padding: 16px 0 0 0;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group {
  display: flex;
  gap: 12px;
  margin: 0 0 10px 0;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .divider {
  border: 1px solid var(--grey90);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card {
  cursor: pointer;
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--grey90);
  gap: 26px;
  transition: width 0.3s linear;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card .card_header {
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  color: var(--grey);
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card .card_header {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card .card_body {
  margin: auto 0 0 0;
  --fs: 24px;
  font-size: 24px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card .card_body {
    --fs: 24px;
    --lh: 150px;
    font-size: 24px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .card_header,
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .card_body,
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .percentage_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .card_header {
  color: var(--gray-g-300);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .percentage_group {
  gap: 3px;
  padding: 2px 8px;
  border-radius: 100px;
  background: #cdfbbd;
  color: var(--accent-green-000);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_type2 .percentage_group svg {
  --svg_color: var(--accent-green-000);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .cards_group .card.card_dark {
  width: 30%;
  background: var(--black);
  color: var(--white);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .order_table {
  --h: 0;
  height: calc(100vh - var(--h) * 1px);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .order_table .header {
  z-index: 11;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .order_table .options_main {
  z-index: 10;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 20px 0 0 0;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .pagination > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .pagination svg {
  width: 15px;
}
body .layout .dashboard_main .orders_page .dashboard_subbody .pagination .prev svg {
  transform: rotate(90deg);
}
body .layout .dashboard_main .orders_page .dashboard_subbody .pagination .next svg {
  transform: rotate(-90deg);
}
body .layout .dashboard_main .customer_page .dashboard_subbody {
  height: calc(100% - 34px);
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .customer_page .customer_table {
  flex-grow: 1;
}
body .layout .dashboard_main .customer_page .customer_table .row > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: unset;
  height: 57px;
}
body .layout .dashboard_main .customer_page .pagination_customer {
  margin: auto 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  padding: 20px 0 0 0;
}
body .layout .dashboard_main .customer_page .pagination_customer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
body .layout .dashboard_main .customer_page .pagination_customer svg {
  height: 15px;
  width: 15px;
}
body .layout .dashboard_main .customer_page .pagination_customer .prev svg {
  transform: rotate(90deg);
}
body .layout .dashboard_main .customer_page .pagination_customer .next svg {
  transform: rotate(-90deg);
}
body .layout .dashboard_main .customer_view .oerview_body {
  display: flex;
  width: 100%;
  display: flex;
  gap: 40px;
}
body .layout .dashboard_main .customer_view .oerview_body > div {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left {
  width: 62%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main {
  display: flex;
  justify-content: center;
  gap: 20px;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection {
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  padding: 20px;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection {
  width: calc(50% - 10px);
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .header {
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .header {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .header .btn {
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  padding: 6px 12px;
  border-radius: 100px;
  background: var(--Gray-G-100, #f1f1f1);
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .header .btn {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .title {
  --fs: 10px;
  font-size: 10px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
  color: var(--Gray-G-400, #777);
  margin: 0 0 8px 0;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .title {
    --fs: 10px;
    --lh: 150px;
    font-size: 10px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .email,
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .phone,
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .address {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  margin: 0 0 8px 0;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .email,
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .phone,
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .address {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .email svg,
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .phone svg,
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .address svg {
  --svg_color: #c5c5c5;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection .phone {
  margin: 0 0 20px 0;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection.details_collection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection.details_collection .chip {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--Gray-G-400, #777);
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
  padding: 2px 6px;
  border-radius: 5px;
  background: var(--Gray-G-100, #f1f1f1);
  display: flex;
  gap: 10px;
  align-items: center;
  --svg_color: #777777;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection.details_collection .chip {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection.details_collection .value {
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .collection_main .collection.details_collection .value {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group {
  border-radius: 20px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .header {
  display: flex;
  justify-content: space-between;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .header {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .header .date {
  color: var(--Gray-G-400, #777);
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  margin: 0 0 8px 0;
  display: flex;
  justify-content: space-between;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .nothingyet {
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: column;
}
@media screen and (max-width: 1000px) {
  body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .nothingyet {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .nothingyet .cust_dropdown {
  width: -moz-fit-content;
  width: fit-content;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .nothingyet .btn {
  text-align: center;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .clinic_card {
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 0px 0px 1px #e5e5e5;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0 0 0;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .clinic_card .photo {
  height: 70px;
  aspect-ratio: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .clinic_card .name {
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
  font-family: "Modern Era Bold WEB";
  color: var(--base);
  margin: 0 0 0px 0;
}
body .layout .dashboard_main .customer_view .oerview_body > div.left .clinic_group .clinic_card .address {
  font-size: 14px;
  line-height: 130%;
  font-weight: 400;
  font-family: "Modern Era Medium WEB";
}
body .layout .dashboard_main .customer_view .oerview_body > div.right {
  width: 38%;
}
body .layout .dashboard_main .customer_view .carehistory_body {
  display: flex;
  width: 100%;
  display: flex;
  gap: 40px;
}
body .layout .dashboard_main .customer_view .healthrecord_body {
  width: 100%;
}
body .layout .dashboard_main .customer_view .healthrecord_body .actions {
  margin: 0 0 12px 0;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}
body .layout .dashboard_main .customer_view .healthrecord_body .actions .btn {
  width: -moz-fit-content;
  width: fit-content;
}
body .layout .dashboard_main .customer_view .healthrecord_body .intake_view {
  width: 100%;
  margin: 0 auto;
  -moz-column-count: 2;
       column-count: 2;
}
body .layout .dashboard_main .customer_view .healthrecord_body .intake_view .intake_card {
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
body .layout .dashboard_main .products_page .products_table {
  height: calc(100vh - 100px);
}
body .layout .dashboard_main .products_page .products_table .product_image {
  background: var(--gray-g-200);
  height: 50px;
  aspect-ratio: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  border: 1px solid var(--gray-g-200);
}
body .layout .dashboard_main .discount_page .dashboard_subheader .title {
  margin: 0 auto 0 0;
}
body .layout .dashboard_main .discount_page .custtable {
  height: unset;
  max-height: calc(100vh - 110px);
  margin: 24px 0 0 0;
}
body .layout .dashboard_main .discount_page .custtable .discount_status {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  --fs: 12px;
  background: #aee9d1;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  padding: 2px 8px;
  width: -moz-fit-content;
  width: fit-content;
}
body .layout .dashboard_main .discount_page .custtable .discount_status[active=false] {
  background: #a4e8f2 !important;
}
body .layout .dashboard_main .discount_page .discount_model {
  position: fixed;
  inset: 0;
  background: rgba(173, 173, 173, 0.137254902);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
body .layout .dashboard_main .discount_page .discount_model .model_main {
  width: 400px;
  background: #fff;
  border: 1px solid #e5e5e5;
  border: 1px solid var(--grey90);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 20px;
  padding: 20px;
  width: 440px;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .line {
  border-top: 2px solid #e5e5e5;
  margin: 24px 0 0 0;
  width: 100%;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .active_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .active_main span {
  font-family: "Modern Era Bold WEB";
}
body .layout .dashboard_main .discount_page .discount_model .model_main .active_main .is_active {
  --fs: 14px;
  border: 1px solid #babfc3;
  border: 1px solid var(--gray111);
  border-radius: 4px;
  font-family: "Modern Era Medium WEB";
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin: 0 0 0 auto;
  padding: 8px 16px;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .active_main .is_active[active=true] {
  background: #008060;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
  color: var(--white);
}
body .layout .dashboard_main .discount_page .discount_model .model_main .title {
  margin: 24px 0 14px 0;
  font-family: Modern Era Bold WEB;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .percent_group .items {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  --fs: 14px;
  font-family: Modern Era Medium WEB;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .percent_group .items:first-child {
  margin: 0 0 18px;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .percent_group .items .switch {
  align-items: center;
  background: #fff;
  background: var(--white);
  border: 2px solid #777;
  border: 2px solid var(--grey);
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  transition: all 0.3s linear;
  width: 18px;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .percent_group .items .switch.active:after {
  aspect-ratio: 1;
  background-color: #000;
  background-color: var(--black);
  border-radius: 50%;
  content: "";
  height: calc(100% - 4px);
}
body .layout .dashboard_main .discount_page .discount_model .model_main .footer {
  display: flex;
  margin: 24px 0 0 0;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .footer .btn {
  --fs: 14px;
  border: 1px solid;
  border-radius: 4px;
  font-family: Modern Era Medium WEB;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px;
  text-align: center;
}
body .layout .dashboard_main .discount_page .discount_model .model_main .footer .btn.save_btn {
  margin: 0 0 0 auto;
  background-color: #000;
  color: #fff;
}
body .layout .login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .layout .login .login_main {
  flex-grow: 1;
  max-width: 400px;
}
body .layout .login .login_main > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
body .layout .login .login_main .btn {
  padding: 20px;
  text-align: center;
  background: var(--base);
  border-radius: 10px;
  color: var(--white);
  font-family: "Modern Era Bold WEB";
  --fs: 18px;
  font-size: 18px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .login .login_main .btn {
    --fs: 18px;
    --lh: 150px;
    font-size: 18px;
    line-height: 150%;
  }
}
body .layout .reports_page {
  height: 100vh;
  width: 100vw;
  padding: 25px;
  overflow: auto;
  font-family: "Modern Era Medium WEB";
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
body .layout .reports_page > .headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .layout .reports_page > .headers .data_controllers {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  overflow: hidden;
  box-shadow: 0px 1px 2px 0px rgba(18, 18, 23, 0.05);
  border-radius: 7px;
  color: var(--Gray-G-500, #454545);
  font-family: "Modern Era Medium WEB";
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
body .layout .reports_page > .headers .data_controllers > div {
  padding: 5px 12px;
}
body .layout .reports_page > .headers .data_controllers > div:not(:last-child) {
  border-right: 1px solid #e5e5e5;
}
body .layout .reports_page > .headers .data_controllers > div[active=true] {
  background: var(--base);
  color: var(--white);
}
body .layout .reports_page > .header1 {
  display: flex;
  gap: 12px;
  margin: 20px 0;
}
body .layout .reports_page > .header1 > div {
  width: 100%;
}
body .layout .reports_page .card {
  border-radius: 12px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 16px;
}
body .layout .reports_page .card > .bg {
  background: var(--Gray-G-000, #fafafa);
}
body .layout .reports_page .card > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body .layout .reports_page .card > .header .txt {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: var(--Gray-G-400, #777);
}
body .layout .reports_page .card > .body .amount {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 12px;
  align-items: end;
}
body .layout .reports_page .card > .body .amount span {
  color: var(--Gray-G-400, #777);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
body .layout .reports_page .chip {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 138.462% */
  border-radius: 8px;
  padding: 4px 8px;
  gap: 3px;
  display: flex;
  align-items: center;
}
body .layout .reports_page .chip.high {
  background: rgba(100, 190, 69, 0.2);
  color: var(--Accent-Green-000, #3a7d23);
  --svg_color: #3a7d23;
}
body .layout .reports_page .chip.low {
  background: var(--Accent-Orange-200, #fdefec);
  color: var(--Accent-Orange-100, #f05e45);
  --svg_color: #f05e45;
}
body .layout .reports_page .chip.low svg {
  transform: rotate(180deg);
}
body .layout .reports_page .custom_tooltip {
  border-radius: 8px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-W-100, #fff);
  box-shadow: 0px 1px 2px 0px rgba(124, 124, 124, 0.05);
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--Gray-G-400, #777);
  --fs: 15px;
  font-size: 15px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  body .layout .reports_page .custom_tooltip {
    --fs: 15px;
    --lh: 150px;
    font-size: 15px;
    line-height: 150%;
  }
}
body .layout .reports_page .custom_tooltip table {
  border-collapse: collapse;
}
body .layout .reports_page .custom_tooltip table tr td {
  padding: 0 12px 0 0;
}
body .layout .reports_page .custom_tooltip table tr td:first-child {
  display: flex;
  align-items: center;
}
body .layout .reports_page .custom_tooltip table tr td:first-child .dot1 {
  height: 6px;
  width: 6px;
  background: #ffa977;
  border-radius: 50%;
  margin: 0 6px 0 0;
}
body .layout .reports_page .custom_tooltip table tr td:first-child .dot2 {
  background: rgba(255, 169, 119, 0.5);
}
body .layout .reports_page .custom_tooltip table tr:first-child {
  border-bottom: 1px solid #e5e5e5;
  color: var(--base);
  text-transform: uppercase;
  font-family: "Modern Era Bold WEB";
}
body .layout .reports_page .custom_tooltip table tr:first-child td {
  padding: 0 12px 3px 0;
}
body .layout .reports_page .custom_tooltip table tr:nth-child(2) td {
  padding: 6px 12px 3px 0;
}
body .layout .reports_page .products {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  margin: 20px 0;
}
body .layout .reports_page .products_table {
  margin: 0;
  color: var(--Base-B-100, #1d1d1d);
}
body .layout .reports_page .products_table .table_header {
  padding: 0;
  background: transparent;
  color: var(--Gray-G-400, #777);
  font-family: "Modern Era TRIAL";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.12px;
}
body .layout .reports_page .products_table .table_header .col_1,
body .layout .reports_page .products_table .table_header .col_2,
body .layout .reports_page .products_table .table_header .col_3,
body .layout .reports_page .products_table .table_header .col_4,
body .layout .reports_page .products_table .table_header .col_5 {
  padding: 18px;
}
body .layout .reports_page .products_table .col_1 {
  width: 10% !important;
}
body .layout .reports_page .products_table .col_2,
body .layout .reports_page .products_table .col_4 {
  width: 20% !important;
  width: 30% !important;
}
body .layout .reports_page .products_table .col_3,
body .layout .reports_page .products_table .col_5 {
  width: 25% !important;
}
body .layout .reports_page .products_table .table_body .row {
  padding: 0;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 138.462% */
}
body .layout .reports_page .products_table .table_body .row .col_1,
body .layout .reports_page .products_table .table_body .row .col_2,
body .layout .reports_page .products_table .table_body .row .col_3,
body .layout .reports_page .products_table .table_body .row .col_4,
body .layout .reports_page .products_table .table_body .row .col_5 {
  text-overflow: ellipsis;
  overflow: hidden !important;
  align-self: stretch;
  padding: 16px;
}
body .layout .reports_page .date_picker {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  padding: 5px 10px;
  cursor: pointer;
}
body .layout .reports_page .date_picker .date_modal {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  z-index: 100;
  border: 1px solid #e5e5e5;
  border-radius: 7px;
  overflow: hidden;
}
body .layout .reports_page .revenue_group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
body .layout .reports_page .custtable {
  height: -moz-fit-content;
  height: fit-content;
}
body .layout .reports_page .passowrd_page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 50px);
}
body .layout .reports_page .passowrd_page > div {
  text-align: center;
  max-width: 432px;
  flex: 0 0 432px;
}
body .layout .reports_page .passowrd_page > div .txt {
  --fs: 20px;
  color: #1d1d1d;
  color: var(--nero);
  font-family: Modern Era Bold WEB;
  font-size: 20px;
  line-height: 112% !important;
  margin: 24px 0 6px;
}
body .layout .reports_page .passowrd_page > div .txt1 {
  --fs: 16px;
  color: #777;
  color: var(--grey);
  font-family: Modern Era Medium WEB;
  font-size: 16px;
  line-height: 150% !important;
  margin: 0 0 24px 0;
}
body .layout .reports_page .passowrd_page > div .btn {
  margin: 24px auto 0 auto;
  background: var(--base);
  text-align: center;
  --fs: 16px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  padding: 16px 20px;
}
@media screen and (max-width: 1000px) {
  body .layout .reports_page .passowrd_page > div .btn {
    --fs: 16px;
    --lh: 150px;
    font-size: 16px;
    line-height: 150%;
  }
}
body .btn {
  cursor: pointer;
  padding: 8px 12px;
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  border-radius: 100px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
}
@media screen and (max-width: 1000px) {
  body .btn {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
body .btn.dark_btn {
  border-radius: 100px;
  border: 1px solid var(--nero);
  background: var(--nero);
  color: var(--white);
}
body .btn_group {
  display: flex;
  gap: 8px;
}

.custom_tooltip {
  border-radius: 8px;
  border: 1px solid var(--Gray-G-200, #e5e5e5);
  background: var(--Gray-W-100, #fff);
  box-shadow: 0px 1px 2px 0px rgba(124, 124, 124, 0.05);
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--Gray-G-400, #777);
}
.custom_tooltip .desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.custom_tooltip .desc .amount {
  color: var(--Gray-G-500, #454545);
  --fs: 14px;
  font-size: 14px;
  line-height: 150%;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
}
@media screen and (max-width: 1000px) {
  .custom_tooltip .desc .amount {
    --fs: 14px;
    --lh: 150px;
    font-size: 14px;
    line-height: 150%;
  }
}
.custom_tooltip .desc .amount .dot1 {
  height: 6px;
  width: 6px;
  background: #ffa977;
  border-radius: 50%;
}
.custom_tooltip .desc .amount .dot2 {
  background: rgba(255, 169, 119, 0.5);
}
.custom_tooltip .desc div {
  --fs: 12px;
  font-size: 12px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .custom_tooltip .desc div {
    --fs: 12px;
    --lh: 150px;
    font-size: 12px;
    line-height: 150%;
  }
}
.custom_tooltip .divider {
  background: var(--Gray-G-200, #e5e5e5);
  height: 1px;
}
.custom_tooltip .intro {
  --fs: 13px;
  font-size: 13px;
  line-height: 150%;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .custom_tooltip .intro {
    --fs: 13px;
    --lh: 150px;
    font-size: 13px;
    line-height: 150%;
  }
}
[color="0"] {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--Accent-Orange-100, #f05e45);
}
[color="0"] svg {
  --svg_color: #f05e45;
}
[color="0"].chips {
  background: var(--Accent-Orange-200, #fdefec) !important;
  color: var(--Accent-Orange-100, #f05e45);
}
[color="0"].chips svg {
  width: 16px;
  height: 16px;
}

[color="1"] {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--Accent-Green-100, #64be45);
}
[color="1"] svg {
  --svg_color: #64be45;
}
[color="1"].chips {
  background: var(--Accent-Orange-200, #eff8ec) !important;
  color: var(--Accent-Green-100, #64be45);
}
[color="1"].chips svg {
  width: 16px;
  height: 16px;
}

[color="2"] {
  width: -moz-fit-content;
  width: fit-content;
  color: rgb(180, 24, 209);
}
[color="2"] svg {
  --svg_color: rgb(180, 24, 209);
}
[color="2"].chips {
  background: rgb(248, 232, 250) !important;
  color: rgb(180, 24, 209);
}
[color="2"].chips svg {
  width: 16px;
  height: 16px;
}

[color="3"] {
  width: -moz-fit-content;
  width: fit-content;
  color: red;
}
[color="3"] svg {
  --svg_color: red;
}
[color="3"].chips {
  background: #ffcccb !important;
  color: red;
}
[color="3"].chips svg {
  width: 16px;
  height: 16px;
}

[color="4"] {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--Accent-Orange-100, #f5a118);
}
[color="4"] svg {
  --svg_color: #f5a118;
}
[color="4"].chips {
  background: #fef3c7 !important;
  color: #f5a118;
}
[color="4"].chips svg {
  width: 16px;
  height: 16px;
}

.chips {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  padding: 0 16px;
  border-radius: 100px;
  border: 2px solid var(--Gray-G-100, #f1f1f1);
  background: #fff;
  color: var(--Gray-G-500, #454545);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  height: 34.6px;
}

.gap-16 {
  padding: 16px 0 0 0;
}

.MuiDateRangeCalendar-root > :nth-child(1) {
  display: none;
}

.sorting_table_header {
  display: flex;
  justify-content: space-between;
}
.sorting_table_header .arrow_group {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sorting_table_header .arrow_group svg {
  height: 15px;
  width: auto;
}
.sorting_table_header .arrow_group svg:last-child {
  transform: rotate(180deg);
}
.sorting_table_header .arrow_group[type=a] svg:last-child path {
  fill: var(--grey);
}
.sorting_table_header .arrow_group[type=d] svg:first-child path {
  fill: var(--grey);
}
.sorting_table_header .arrow_group[type=n] {
  display: none;
}